.App {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.random-btn {
  border: none;
  cursor: pointer;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 10;
}

.random-image {
  max-width: 70%;
  max-height: auto;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.random-btn img:hover {
  transform: scale(1.1);
}

.random-btn img:active {
  transform: scale(1.1);
}

.title {
  margin-bottom: 1rem;
}

.click-image {
  position: absolute;
  width: 25%;
  height: auto;
  top: 65%;
  left: 60%;
  z-index: 10;
}

@media (max-width: 767px) {
  .random-btn {
    max-width: 80%;
  }

  .random-btn img {
    max-width: 100%;
    max-height: 100%;
  }

  .title {
    top: 20%;
  }

  .click-image {
    width: 25%;
    top: 68%;
    left: 70%;
  }
}

@media (max-width: 768px) {
  .loading {
    max-width: 70%;
  }

  @keyframes expandWidth {
    from {
      max-width: 70%;
    }
    to {
      max-width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .loading {
    max-width: 50%;
  }
}

.loading {
  border: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: expandWidth 2s forwards;
  z-index: 10;
}

.loading h1 {
  margin-bottom: 1rem;
}

.loading img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
