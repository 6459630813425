.info-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.info {
  margin-bottom: 20px;
}

.info h2 {
  font-size: 24px;
  color: #3d3d3c;
}

.info img {
  max-width: 100%;
  max-height: 30vh;
  object-fit: contain;
  margin: 3vh 0;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.info p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.info-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  gap: 5px;
}

.image-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .info-container {
    width: 90%;
    max-width: 90%;
    max-height: 90vh;
  }

  .info-btn-container {
    width: 100%;
    gap: 0px;
  }
}

@media (min-width: 768px) {
  .info-container {
    width: 70%;
    max-width: 80%;
    max-height: 80vh;
  }

  .info p {
    margin-top: 20px;
  }
}
